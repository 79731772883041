<template>
    <div id="loginClient">
      <div class="banner">
        <div class="bgAnimation">
            <BgAnimation />
        </div>
        <div class="login-inner">
          <slot></slot>
          <div class="footer">
            <div class="footer-left">
                <h1 class="socialMediaTitle">{{ $t('common.socialMedia.title') }}</h1>
                <SocialMedia></SocialMedia>
            </div>
            <div class="footer-right">
                Copyright © {{currentYear}}. PU Prime.
            </div>
            </div>
      </div>
        </div>
    </div>
  </template>
  
  <style lang="scss" scoped>
  @import '@/assets/css/components/template/staticPage/staticPagePug.scss';
  </style>
  
  <script>
  import SocialMedia from '@/components/SocialMedia';
  import BgAnimation from '@/components/BgAnimation'
  import moment from 'moment';

  export default {
    name: 'StaticPagePug',
    components: { SocialMedia, BgAnimation },
    data() {
        return {
            dynamicVH: '100vh',
            currentYear: '',
        };
    },
    mounted() {
        this.currentYear = moment().year();
        setTimeout(() => {
        this.setDynamicVH();

        }, 0)
        window.addEventListener('resize', this.setDynamicVH);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setDynamicVH);
    },
    methods: {
        setDynamicVH() {
            const vh = window.innerHeight;

            const formWrapper = document.querySelector('.form-wrapper');
            const footer = document.querySelector('.footer');
            if (formWrapper && footer) {
                const totalHeight = formWrapper.offsetHeight + footer.offsetHeight;
                if (vh <= 900) {
                    this.dynamicVH = '900px';
                } else {
                    // this.dynamicVH = totalHeight > 900 ? `${totalHeight + 48 + 100}px` : '100vh';
                }
                document.documentElement.style.setProperty('--dynamic-vh', this.dynamicVH);
            }
        }
    }
};
  </script>
  