<template>
  <div class="socialMedia">
    <div class="brand" v-for="(media, key, index) in socialMedia" :key="'social-' + key + index">
      <a :href="media.link" target="_blank"><img :alt="media.name" :src="media.img"/></a>
    </div>
  </div>
</template>

<script>
import fbLogo from '@/assets/images/social/fb.png';
import inLogo from '@/assets/images/social/in.png';
import twitterLogo from '@/assets/images/social/twitter.png';
import igLogo from '@/assets/images/social/ig.png';
import youtubeLogo from '@/assets/images/social/youtube.png';
import tiktokLogo from '@/assets/images/social/tiktok.png';

export default {
  name: 'SocialMedia',
  data() {
    return {
      socialMedia: [
        { name: 'Facebook', link: 'https://www.facebook.com/puprime', img: fbLogo },
        { name: 'Twitter', link: 'https://twitter.com/puprime', img: twitterLogo },
        { name: 'Linked In', link: 'https://www.linkedin.com/company/puprime/', img: inLogo },
        {
          name: 'Instagram',
          link: 'https://www.instagram.com/puprime_official/',
          img: igLogo
        },
        {
          name: 'Youtube',
          link: 'https://www.youtube.com/channel/UCmhvCX6JOPiHvfnveFKmWxA/videos',
          img: youtubeLogo
        },
        { name: 'Tiktok', link: 'https://www.tiktok.com/@puprime', img: tiktokLogo }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.socialMedia {
  display: flex;
  img {
    width: 36px;
    margin-right: 16px;
  }
}
</style>
