<template>
  <div class="container" ref="container">
    <svg xmlns="http://www.w3.org/2000/svg" class="box-a box" width="2131" height="2131" viewBox="0 0 2131 2131" fill="none" preserveAspectRatio="xMidYMid meet">
        <g filter="url(#filter0_d_9603_33597)">
            <path d="M717.962 671.323C746.716 557.926 864.861 466 981.847 466H1164.65L912.686 1459.68C883.933 1573.07 765.788 1665 648.802 1665H466L717.962 671.323Z" fill="#004ABC"/>
            <path d="M1218.31 671.323C1247.07 557.926 1365.21 466 1482.2 466H1665L1573.99 824.913C1545.24 938.309 1427.09 1030.24 1310.11 1030.24H1127.31L1218.31 671.323Z" fill="#004ABC"/>
        </g>
        <defs>
            <filter id="filter0_d_9603_33597" x="0.549438" y="0.549438" width="2129.9" height="2129.9" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="232.725"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9603_33597"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9603_33597" result="shape"/>
            </filter>
        </defs>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" class="box-b box" width="1482" height="1482" viewBox="0 0 1482 1482" fill="none" preserveAspectRatio="xMidYMid meet">
        <g filter="url(#filter0_d_9603_33598)">
            <path d="M499.26 466.819C519.26 387.942 601.439 324 682.812 324H809.966L634.706 1015.18C614.705 1094.06 532.526 1158 451.153 1158H324L499.26 466.819Z" fill="#004ABC"/>
            <path d="M847.294 466.818C867.294 387.942 949.473 324 1030.85 324H1158L1094.7 573.652C1074.7 652.529 992.517 716.471 911.144 716.471H783.991L847.294 466.818Z" fill="#004ABC"/>
        </g>
        <defs>
            <filter id="filter0_d_9603_33598" x="0.242218" y="0.242218" width="1481.52" height="1481.52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="161.879"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9603_33598"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9603_33598" result="shape"/>
            </filter>
        </defs>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" class="box box-c" width="1122" height="1467" viewBox="0 0 1122 1467" fill="none" preserveAspectRatio="xMidYMid meet">
        <g filter="url(#filter0_d_9603_33599)">
            <path d="M494.369 462.277C514.153 384.252 595.445 321 675.94 321H801.721L628.353 1004.72C608.568 1082.75 527.276 1146 446.781 1146H321L494.369 462.277Z" fill="#004ABC"/>
        </g>
        <defs>
            <filter id="filter0_d_9603_33599" x="0.736023" y="0.736023" width="1121.25" height="1465.53" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="160.132"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9603_33599"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9603_33599" result="shape"/>
            </filter>
        </defs>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" class="box box-d" width="789" height="804" viewBox="0 0 789 804" fill="none" preserveAspectRatio="xMidYMid meet">
        <g filter="url(#filter0_d_9603_33601)">
            <path d="M298.882 360.282C314.326 299.375 377.783 250 440.617 250H538.803L489.922 442.777C474.478 503.684 411.02 553.059 348.186 553.059H250L298.882 360.282Z" fill="#006BFF"/>
        </g>
        <defs>
            <filter id="filter0_d_9603_33601" x="0" y="0" width="788.803" height="803.059" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="125"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9603_33601"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9603_33601" result="shape"/>
            </filter>
        </defs>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" class="box box-e" width="789" height="804" viewBox="0 0 789 804" fill="none">
        <g filter="url(#filter0_d_9603_33600)">
            <path d="M298.882 360.282C314.326 299.375 377.783 250 440.617 250H538.803L489.922 442.777C474.478 503.684 411.02 553.059 348.186 553.059H250L298.882 360.282Z" fill="#006BFF"/>
        </g>
        <defs>
            <filter id="filter0_d_9603_33600" x="0" y="0" width="788.803" height="803.059" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset/>
                <feGaussianBlur stdDeviation="125"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9603_33600"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9603_33600" result="shape"/>
            </filter>
        </defs>
    </svg>
    <div class="slogan" id="typingText"></div>
  </div>
</template>

<script>
export default {
    name: 'BgAnimation',
    data() {
        return {}
    },
    mounted() {
        this.$nextTick(() => {
            const container = this.$refs.container;
            const boxes = container.querySelectorAll('.box');

            function getRandomIntInRange(min, max) {
                return Math.floor(Math.random() * (max - min + 1)) + min;
            }

            const minX = -window.innerWidth / 8;  // 负向运动范围减小为窗口宽度的八分之一
            const minY = -window.innerHeight / 8; // 负向运动范围减小为窗口高度的八分之一

            const baseWidth = 789; // 最小的svg宽为基
            const baseXHalve = 8;

            let maxX = window.innerWidth / baseXHalve;
            const maxY = window.innerHeight / 8;
            const duration = 170;

            boxes.forEach(box => {
                const { width, left } = box.getBoundingClientRect();
                const XRate = width / baseWidth;
                /**
                 * (window.innerWidth / (XRate * baseXHalve))  正向动范围减小为窗口宽度的(baseXHalve)八分之一 宽度越大减少越多
                 * left / (6 * XRate) 减少6倍初始化left叶子的maxX值，宽度越大减少越多
                 */
                maxX = (window.innerWidth / (XRate * baseXHalve)) - left / (6 * XRate);
                // box.style.setProperty('--duration', `${duration / XRate}s`); // 全部匀速
                box.style.setProperty('--duration', `${duration}s`);
                for (let i = 1; i <= 10; i++) {
                    const randomX = getRandomIntInRange(minX, maxX) + 'px';
                    const randomY = getRandomIntInRange(minY, maxY) + 'px';
                    box.style.setProperty(`--x${i}`, randomX);
                    box.style.setProperty(`--y${i}`, randomY);
                }
            });

            // 标语动画
            const text = this.$t('loginClient.slogan');
            const typingTextElement = document.getElementById('typingText');

            const typeWriter = (text, element, delay = 100) => {
                let index = 0;
                const intervalId = setInterval(() => {
                    element.textContent += text[index];
                    index++;
                    if (index >= text.length) {
                    clearInterval(intervalId);
                    }
                }, delay);
            }

            const totalTime = 2700;
            typeWriter(text, typingTextElement, totalTime / text.length);
        });
        
    }
}
</script>

<style lang="scss" scoped>
    .container {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-color: #000;
        }
        .box {
            position: absolute;
            animation: move var(--duration) linear infinite alternate;
        }
        /** 
        *  box-a (2131px - 1199px)计算出阴影部分 2131px包含阴影大小 1199px实际大小
        */
        .box-a {
            position: absolute;
            @include rtl-sass-value(left, calc(0px - ((2131px - 1199px) / 2 + 110px)), unset);
            @include rtl-sass-value(right, unset, calc(0px - ((2131px - 1199px) / 2 + 110px)));
            top: calc(0px - ((2131px - 1199px) / 2 + 96px));
            animation: move 110s linear infinite alternate;
        }
        .box-b {
            position: absolute;
            @include rtl-sass-value(left, calc(0px - ((1482px - 834px) / 2 + 346px)), unset);
            @include rtl-sass-value(right, unset, calc(0px - ((1482px - 834px) / 2 + 346px)));
            top: calc(0px - (1482px - 834px) / 2);
            animation: move 110s linear infinite alternate;
        }
        .box-c {
            @include rtl-sass-value(left, calc(0px - ((1122px - 480px) / 2 - 118px)), unset);
            @include rtl-sass-value(right, unset, calc(0px - ((1122px - 480px) / 2 - 118px)));
            bottom: calc(0px - ((1467px - 825px) / 2 + 266px));
        }
        .box-d {
            @include rtl-sass-value(left, calc(0px - ((789px - 289px) / 2 + 110px)), unset);
            @include rtl-sass-value(right, unset, calc(0px - ((789px - 289px) / 2 + 110px)));
            bottom: calc(0px - ((804px - 303px) / 2 - 146px));
        }
        .box-e {
            @include rtl-sass-value(left, calc(0px - ((789px - 289px) / 2 - 397px)), unset);
            @include rtl-sass-value(right, unset, calc(0px - ((789px - 289px) / 2 - 397px)));
            bottom: calc(0px - ((804px - 303px) / 2 - 31px));
        }
        @keyframes move {
            0% { transform: translate(0, 0); }
            10% { transform: translate(var(--x1), var(--y1)); }
            20% { transform: translate(var(--x2), var(--y2)); }
            30% { transform: translate(var(--x3), var(--y3)); }
            40% { transform: translate(var(--x4), var(--y4)); }
            50% { transform: translate(var(--x5), var(--y5)); }
            60% { transform: translate(var(--x6), var(--y6)); }
            70% { transform: translate(var(--x7), var(--y7)); }
            80% { transform: translate(var(--x8), var(--y8)); }
            90% { transform: translate(var(--y9), var(--y9)); }
            100% { transform: translate(var(--x10), var(--y10)); }
        }
        .slogan {
            color: $white;
            font-size: 50px;
            font-weight: 900;
            height: 50px;
            line-height: 50px;
            text-transform: uppercase;
            position: absolute;
            @include rtl-sass-value(left, 17.7%, unset);
            @include rtl-sass-value(right, unset, 17.7%);
            top: calc((100% - 50px) / 2);
            transform:translate(-0,-50%);
            overflow: hidden; /* 隐藏超出容器的内容 */
            white-space: nowrap; /* 禁止文字换行 */
        }
</style>